<template>
    <div class="wrapper" id="products">
      <!-- Start Portfolio -->
      <section class="portfolio">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sec-title">
                <h1>Products</h1>
                <h2>Our products</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="sec-content">
                <div class="service-container">
                  <div class="row g-4">
                    
                    <div class="col-12 col-md-4">
                      <div class="service-item">
                        <div class="service-card">
                          <div class="product-card">
                            <img src="assets/img/listing-screenshot.png" alt="Property Listing" class="img-fluid"> <br>
                            <h3>Upcoming Property Listing</h3>
                            <p>Discover convenient and affordable housing options designed to meet your needs.</p>
                            <button type="button" class="button"><a href="https://makkan-listings.vercel.app/">View Product</a></button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-4">
                      <div class="service-item">
                        <div class="service-card">
                          <div class="product-card">
                            <img src="assets/img/store-screenshot.png" alt="Property Listing" class="img-fluid"> <br>
                            <h3>Makkan Store</h3>
                            <p>Discover exclusive Makkan Innovation merch! Shop limited-edition hoodies, T-shirts, and sweatshirts designed for visionaries and innovators</p>
                            <button type="button" class="button"><a href="https://store.makkaninnovation.com/">View Store</a></button>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <!-- End Portfolio -->
    </div>
</template>