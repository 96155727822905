<template>
    <div class="wrapper" id="services">
        <!-- Start Services -->
      <section class="services" id="services">
        <div class="container h-100">
          <div class="row">
            <div class="col-12">
              <div class="sec-title">
                <h1>Services</h1>
                <h2>Our Services</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="sec-content">
                <div class="service-container">
                  <div class="row g-4">
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-code-alt"></i>
                          <h3>Craft Responsive Web Development & Design</h3>
                          <p>
                            Tailor dynamic websites to your business needs, leveraging the latest technologies for a seamless user experience and impactful online presence.
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-mobile-alt"></i>
                          <h3>Create High-Performance Mobile Applications</h3>
                          <p>
                            Develop intuitive apps for iOS and Android, transforming your ideas into powerful solutions that stand out in a competitive market..
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-laptop"></i>
                          <h3>Equip with Immersive Coding Bootcamps</h3>
                          <p>
                            Provide essential tech skills through hands-on training and real-world projects, led by industry experts.
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-bar-chart-alt-2"></i>
                          <h3>Boost Online Visibility with SEO Services</h3>
                          <p>
                            Implement proven strategies to enhance search engine rankings, driving organic traffic and expanding your reach.
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-share-alt"></i>
                          <h3>Develop Comprehensive Social Media Strategies</h3>
                          <p>
                            Enhance your brand’s presence and effectively engage your audience, driving customer loyalty and growth.
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Services -->
    </div>
</template>