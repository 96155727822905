<template>
    <div class="wrapper" id="about">
      <!-- Start About -->
      <section class="about">
        <div class="container h-100">
          <div class="row">
            <div class="col-12">
              <div class="sec-title">
                <h1>About</h1>
                <h2>Who are we?</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row g-5">
                <div class="col-lg-4">
                  <div class="sec-image">
                    <img src="assets/img/Segun-modified.jpg" alt="Olusegun Joe-Alabi" class="img-fluid about-img" />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="sec-content mt-5">
                    <p>
                      Makkan Innovation is a remote-first software agency specializing in web and mobile app development, 
                      coding bootcamps, SEO services and social media management. We create transformative products that empower business 
                      owners to thrive in the digital landscape.
                    </p>
                    <p>
                      To lead digital innovation by providing transformative solutions that empower businesses globally,
                      driven by innovation, integrity, and excellence.
                    </p>
                    <p>
                      - Joe-Alabi Olusegun <br>
                      <i>Founder/CEO</i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End About -->
    </div>
</template>